import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import _ from 'lodash'
import * as Actions from './redux/actions'
import * as HeaderActions from '../common/redux/actions.header'
import * as NotificationActions from '../common/redux/actions.notifications'
import CoursesApi from './api'
import styles from './styles/CourseItem.index.styles.js'
import AuthenticatedPage from '../common/AuthenticatedPage.jsx'
import AlfLinearProgress from '../common/AlfLinearProgress'
import {getItemType, itemTypes, isLastItem, findNextItem} from './Utils'
import CourseItemText from './CourseItem.text'
import CourseItemImage from './CourseItem.image'
import CourseItemVideo from './CourseItem.video'
import CourseItemChoice from './CourseItem.choice'
import CourseItemTrueFalse from './CourseItem.trueFalse'
import TimerLinearWithValueLabel from './CourseItem.timerCountDown'

function CourseItem({
  classes,
  params,
  getCourse,
  courses,
  router,
  createError,
  create,
  startCourseItem,
  setLastCompletedCourseItem,
  createNotificationFromError,
}) {
  const getCourseItem = course => {
    return _.find(course?.items, {id: parseInt(_.last(_.get(params, 'id')) || 0, 10)})
  }

  const [hasMinDurationPassed, setHasMinDurationPassed] = useState(false)
  const [isCompletingItem, setIsCompletingItem] = useState(false)
  const {course, isFetching} = courses
  const item = getCourseItem(course)
  const itemType = getItemType(item)

  if (course && !item) {
    createError('Course item not found.')
    setLastCompletedCourseItem(null)
    router.push(`/course/${_.first(_.get(params, 'id'))}`)
  }

  useEffect(() => {
    const item = getCourseItem(course)
    if (item) startCourseItem(item.id)
  }, [router.location.pathname])

  useEffect(() => {
    if (_.isEmpty(courses?.course)) {
      getCourse(_.first(_.get(params, 'id')))
    }
  }, [])

  const completeCourseItem = async (item, answer) => {
    const itemId = _.get(item, 'id')
    if (!itemId) return
    setIsCompletingItem(true)
    try {
      const response = await CoursesApi.completeCourseItem(itemId, answer)
      setLastCompletedCourseItem(item)
      goToNextItem(item, response)
    } catch (e) {
      console.warn(e)
      if (e.response.status !== 400) {
        createError(`${e.response.status} - ${e.response.statusText}`)
        return
      }
      createNotificationFromError(e)
    } finally {
      setIsCompletingItem(false)
    }
  }

  const goToNextItem = (item, response) => {
    if (item.order === course?.items.length || isLastItem(item, course?.items)) {
      if (course.statementRequired && response?.score >= course?.minimumScore) {
        router.push(`/course/${_.first(_.get(params, 'id'))}/signature`)
      } else {
        router.push(`/course/${_.first(_.get(params, 'id'))}/summary`)
      }
      return
    }
    const nextItem = findNextItem(item, course?.items)

    if (nextItem && _.get(course, 'id')) {
      router.push(`/course/${_.get(course, 'id')}/item/${_.get(nextItem, 'id')}`)
    } else {
      create('warning', "Couldn't find the next course item")
    }
  }

  return (
    <div className={classes.root}>
      {isFetching && <AlfLinearProgress />}
      {!_.isEmpty(course) && (
        <React.Fragment>
          <h2>{item?.title || ''}</h2>
          <TimerLinearWithValueLabel
            item={item}
            router={router}
            hasMinDurationPassed={hasMinDurationPassed}
            setHasMinDurationPassed={setHasMinDurationPassed}
          />

          <div className={classes.mainCont}>
            {itemType === itemTypes.TEXT && (
              <CourseItemText
                item={item}
                hasMinDurationPassed={hasMinDurationPassed}
                onCourseItemComplete={completeCourseItem}
                isCompletingItem={isCompletingItem}
              />
            )}
            {itemType === itemTypes.CHOICE && (
              <CourseItemChoice
                item={item}
                hasMinDurationPassed={hasMinDurationPassed}
                onCourseItemComplete={completeCourseItem}
                isCompletingItem={isCompletingItem}
              />
            )}
            {itemType === itemTypes.TRUE_FALSE && (
              <CourseItemTrueFalse
                item={item}
                hasMinDurationPassed={hasMinDurationPassed}
                onCourseItemComplete={completeCourseItem}
                isCompletingItem={isCompletingItem}
                router={router}
              />
            )}
            {itemType === itemTypes.IMAGE && (
              <CourseItemImage
                item={item}
                hasMinDurationPassed={hasMinDurationPassed}
                onCourseItemComplete={completeCourseItem}
                isCompletingItem={isCompletingItem}
              />
            )}
            {itemType === itemTypes.VIDEO && (
              <CourseItemVideo
                item={item}
                hasMinDurationPassed={hasMinDurationPassed}
                onCourseItemComplete={completeCourseItem}
                isCompletingItem={isCompletingItem}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

CourseItem.displayName = 'CourseItem'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions, ...NotificationActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
  AuthenticatedPage,
)(CourseItem)
